.wrapper {
  width: 80%;
  margin: 0 auto;
  padding: 2rem 0; 

  @media (min-width:1200px) {
    padding: 4rem 0; 
  }
  @media (min-width:1700px) {
    width: 60%;
  }
}

.titleSpan {
  color: #029EF5;
  font-weight: 700;
}

.mySlider {
  width: 100%;
  margin: 1rem 0 3rem;
}

.divTitle {
  margin: 0 auto;
  
  @media (min-width:1200px) {
    display: grid;
    grid-template-columns: 45% 1fr;
    grid-template-areas: 
    "titleAbout .";
  }
  @media (min-width:1700px) {
    width: 100%;
  }
}

.myClass {
  box-shadow: 0px 0px 8px 0px rgba(0,0,0,0.3);
  width: 100%;
  background-color: #029EF5;
  color: #fff;
  display: flex;
  margin-top: 3rem;

  @media (min-width:700px) {
    margin-top: 2rem;
  }

  @media (min-width:1200px) {
    width: 60%;
    margin: 1rem auto 0;
  }
  @media (min-width:1700px) {
    transform: translate(50px, -30px);
  }
}