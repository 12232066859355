.title {
  grid-area: titleAbout;
  font-size: 1.7rem;
  font-weight: 600;
  padding: 1rem 0;
  line-height: 1.1;
  opacity: 0;
  transform: translateY(60px);

  @media (min-width:1200px) {
    font-size: 2rem;
    padding: 2rem 0;
    line-height: 1.3;
  }
}