.wrapper {
  width: 80%;
  margin: 3rem auto;
  background-color: #fff;
  display: grid;
  grid-template-areas: 
    "titleAbout"
    "textAbout";
  grid-auto-rows: min-content;
  align-content: center;

  @media (min-width:1000px) {
    margin: 5rem auto;
    grid-template-columns: 1fr 20% 50%;
    grid-template-rows: auto 1fr;
    grid-template-areas: 
    "titleAbout titleAbout ."
    ". textAbout textAbout";
  }
  @media (min-width:1700px) {
    width: 60%;
  }
}

.titleSpan {
  color: #029EF5;
  font-weight: 700;
}

.textAbout {
  grid-area: textAbout;
  font-size: 1.1rem;
  padding: 1rem 0;
  opacity: 0;
  transform: translateY(60px);
  
  @media (min-width:1200px) {
    font-size: 1.4rem;
    padding: 2rem 0;
  }
}