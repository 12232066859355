.oneTestimonial {
  display: flex;
  flex-direction: column;
  padding: 2rem 1rem;
  width: 100%;
  height: 100%;

  @media (min-width:1200px) {
    padding: 3rem;
  }
  @media (min-width:1700px) {
    padding: 3rem 3.5rem;
  }

  &Text {
    font-style: italic;

    @media (min-width:1200px) {
      font-size: 1.1rem;
    }
    @media (min-width:1700px) {
      font-size: 1.2rem;
    }
  }

  &Company {
    margin-top: 1rem;
    font-weight: 700;
    display: inline-flex;
    justify-content: flex-end;

    @media (min-width:1700px) {
      font-size: 1.4rem;
      margin-top: 2rem;
    }
  }
}