.wrapper {
  width: 100%;
  min-height: 100vh;
  background-color: #fff;
  padding: 1rem 0;
}

.text {
  height: 58vh;
  width: 80%;
  padding: 1rem 0;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  justify-content: center;

  @media (min-width:760px) {
    height: 45vh;
  }
  @media (min-width:1200px) {
    height: 55vh;
  }
  @media (min-width:1700px) {
    width: 60%;
  }

  &H1 {
    font-weight: 700;
    font-size: 2.2rem;
    line-height: 1.1;
    padding: 1rem 0;
    margin-top: 5rem;

    .titleSpan {
      color: #029EF5;
    }

    @media (min-width:760px) {
      margin-top: 7rem;
      font-size: 2.3rem;
      padding: 1.5rem 0;
    }
    @media (min-width:1200px) {
      font-size: 2.5rem;
      padding: 1rem 0;
    }
  }

  &H3 {
    font-weight: 300;
    font-size: 1.1rem;
    line-height: 1.4;

    @media (min-width:760px) {
      font-size: 1.7rem;
      line-height: 1.3;
    }
    @media (min-width:1200px) {
      padding-right: 10rem;
    }
  }
}

.img {
  width: 100%;
  height: 50vh;
  // padding: .5rem 0;
  margin: 0 auto;
  overflow: hidden;
  box-shadow: 0px 0px 5px 0px rgba(0,0,0,0.4);

  @media (min-width:760px) {
    width: 90%;
    height: 40vh;
  }
  @media (min-width:1200px) {
    height: 70vh;
  }
  @media (min-width:1700px) {
    width: 70%;
  }

  .videoHosting {
    min-width: 100%;
    height: 100%;
    object-fit: cover;
    // transform: translate(-45%, -35%);

    @media (min-width:1200px) {
      // transform: translate(-40%, -30%);
    }
  }
}
