@import url(https://fonts.googleapis.com/css?family=Montserrat:300,400,500,600,700,800,900&display=swap&subset=latin-ext);
*, *::before, *::after {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

body {
  font-family: 'Montserrat', sans-serif;
  font-size: 1rem;
  line-height: 1.6;
  background-color: #fff;
  color: #0A0A0A;
}

body::-webkit-scrollbar {
  width: 5px;
}

body::-webkit-scrollbar-track {
  background-color: #fff;
}

body::-webkit-scrollbar-thumb {
  background-color: #0A0A0A;
}

.Header_wrapper__3EEP9 {
  width: 100%;
  min-height: 100vh;
  background-color: #fff;
  padding: 1rem 0; }

.Header_text__3i8Ls {
  height: 58vh;
  width: 80%;
  padding: 1rem 0;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  justify-content: center; }
  @media (min-width: 760px) {
    .Header_text__3i8Ls {
      height: 45vh; } }
  @media (min-width: 1200px) {
    .Header_text__3i8Ls {
      height: 55vh; } }
  @media (min-width: 1700px) {
    .Header_text__3i8Ls {
      width: 60%; } }
  .Header_textH1__2XkS9 {
    font-weight: 700;
    font-size: 2.2rem;
    line-height: 1.1;
    padding: 1rem 0;
    margin-top: 5rem; }
    .Header_textH1__2XkS9 .Header_titleSpan__3Jm-L {
      color: #029EF5; }
    @media (min-width: 760px) {
      .Header_textH1__2XkS9 {
        margin-top: 7rem;
        font-size: 2.3rem;
        padding: 1.5rem 0; } }
    @media (min-width: 1200px) {
      .Header_textH1__2XkS9 {
        font-size: 2.5rem;
        padding: 1rem 0; } }
  .Header_textH3__aEPyT {
    font-weight: 300;
    font-size: 1.1rem;
    line-height: 1.4; }
    @media (min-width: 760px) {
      .Header_textH3__aEPyT {
        font-size: 1.7rem;
        line-height: 1.3; } }
    @media (min-width: 1200px) {
      .Header_textH3__aEPyT {
        padding-right: 10rem; } }

.Header_img__3qC1n {
  width: 100%;
  height: 50vh;
  margin: 0 auto;
  overflow: hidden;
  box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.4); }
  @media (min-width: 760px) {
    .Header_img__3qC1n {
      width: 90%;
      height: 40vh; } }
  @media (min-width: 1200px) {
    .Header_img__3qC1n {
      height: 70vh; } }
  @media (min-width: 1700px) {
    .Header_img__3qC1n {
      width: 70%; } }
  .Header_img__3qC1n .Header_videoHosting__1hHHG {
    min-width: 100%;
    height: 100%;
    object-fit: cover; }

.Company_wrapper__3OVT8 {
  width: 80%;
  margin: 3rem auto;
  background-color: #fff;
  display: grid;
  grid-template-areas: "titleAbout" "textAbout";
  grid-auto-rows: -webkit-min-content;
  grid-auto-rows: min-content;
  align-content: center; }
  @media (min-width: 1000px) {
    .Company_wrapper__3OVT8 {
      margin: 5rem auto;
      grid-template-columns: 1fr 20% 50%;
      grid-template-rows: auto 1fr;
      grid-template-areas: "titleAbout titleAbout ." ". textAbout textAbout"; } }
  @media (min-width: 1700px) {
    .Company_wrapper__3OVT8 {
      width: 60%; } }

.Company_titleSpan__2YeQr {
  color: #029EF5;
  font-weight: 700; }

.Company_textAbout__3CwQs {
  grid-area: textAbout;
  font-size: 1.1rem;
  padding: 1rem 0;
  opacity: 0;
  -webkit-transform: translateY(60px);
          transform: translateY(60px); }
  @media (min-width: 1200px) {
    .Company_textAbout__3CwQs {
      font-size: 1.4rem;
      padding: 2rem 0; } }

.Title_title__3Nx8u {
  grid-area: titleAbout;
  font-size: 1.7rem;
  font-weight: 600;
  padding: 1rem 0;
  line-height: 1.1;
  opacity: 0;
  -webkit-transform: translateY(60px);
          transform: translateY(60px); }
  @media (min-width: 1200px) {
    .Title_title__3Nx8u {
      font-size: 2rem;
      padding: 2rem 0;
      line-height: 1.3; } }

.Panel_wrapper__2wf0I {
  width: 100%;
  position: fixed;
  top: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: space-between;
  z-index: 10;
  padding: 1rem; }
  @media (min-width: 1700px) {
    .Panel_wrapper__2wf0I {
      padding: 1rem 5rem; } }

.Panel_logo__22WBo {
  width: 7em;
  padding: .5rem; }
  @media (min-width: 1700px) {
    .Panel_logo__22WBo {
      width: 10em; } }

.Panel_contact__39RLc {
  text-decoration: none; }

.Panel_text__3LHej {
  position: relative;
  font-weight: 900;
  color: #0A0A0A;
  cursor: pointer; }
  .Panel_text__3LHej::before {
    position: absolute;
    content: '';
    bottom: 0px;
    left: 0;
    width: 100%;
    height: 2px;
    background: rgba(9, 203, 222, 0.8);
    z-index: -1;
    -webkit-transition: .4s;
    transition: .4s; }
  .Panel_text__3LHej:hover::before {
    height: 10px; }
  @media (min-width: 1700px) {
    .Panel_text__3LHej {
      font-size: 1.3rem; } }
  .Panel_text__3LHej .Panel_titleSpan__3hI_k {
    color: #029EF5; }

.Customers_wrapper__3eL_7 {
  width: 90%;
  margin: 2rem auto;
  background-color: #fff; }
  @media (min-width: 700px) {
    .Customers_wrapper__3eL_7 {
      width: 50%; } }
  @media (min-width: 1700px) {
    .Customers_wrapper__3eL_7 {
      width: 40%;
      margin: 8rem auto; } }

.Customers_title__kDvNm {
  font-weight: 300;
  text-align: center;
  opacity: 0;
  -webkit-transform: translateY(60px);
          transform: translateY(60px); }
  .Customers_titleSpan__9Jtkb {
    color: #029EF5; }
  @media (min-width: 1200px) {
    .Customers_title__kDvNm {
      font-size: 1.2rem; } }

.Customers_logos__3P1OW {
  padding: 2rem 0;
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  justify-content: center;
  align-items: center; }
  .Customers_logosImg__1ksv0 {
    width: 33%;
    padding: .5rem 1rem;
    -webkit-transform: translateY(30px) scale(0);
            transform: translateY(30px) scale(0);
    -webkit-transform-origin: bottom;
            transform-origin: bottom;
    opacity: 0; }
    @media (min-width: 700px) {
      .Customers_logosImg__1ksv0 {
        width: 25%; } }
    @media (min-width: 1200px) {
      .Customers_logosImg__1ksv0 {
        width: 22%; } }
    @media (min-width: 1700px) {
      .Customers_logosImg__1ksv0 {
        width: 18%; } }

.Products_wrapper__1jQfP {
  width: 100%;
  margin: 0;
  background-color: #fff;
  padding: 1rem 0; }
  @media (min-width: 1200px) {
    .Products_wrapper__1jQfP {
      margin: 3rem auto; } }
  @media (min-width: 1700px) {
    .Products_wrapper__1jQfP {
      width: 60%; } }

.Products_divTitle__3vSMD {
  width: 80%;
  margin: 0 auto; }
  @media (min-width: 1200px) {
    .Products_divTitle__3vSMD {
      display: grid;
      grid-template-columns: 50% 1fr;
      grid-template-areas: "titleAbout ."; } }
  @media (min-width: 1700px) {
    .Products_divTitle__3vSMD {
      width: 100%; } }

.Products_titleSpan__3jVfc {
  color: #029EF5;
  font-weight: 700; }

.ItemProduct_wrapper__3J49x {
  width: 100%;
  padding: 2rem 0;
  margin: 0 auto;
  overflow: hidden; }
  @media (min-width: 700px) {
    .ItemProduct_wrapper__3J49x {
      width: 80%;
      display: grid;
      grid-template-columns: 1fr 1fr;
      grid-template-rows: 1fr 1fr;
      grid-template-areas: "vps hosting" "vps domain";
      grid-gap: 3rem;
      align-items: center; } }
  @media (min-width: 1200px) {
    .ItemProduct_wrapper__3J49x {
      padding: 0; } }
  @media (min-width: 1700px) {
    .ItemProduct_wrapper__3J49x {
      width: 100%; } }

.ItemProduct_divProductHosting__3OISL,
.ItemProduct_divProductVPS__3lvyH,
.ItemProduct_divProductDomain__CCcYX {
  -webkit-transform: translateY(50px) scale(0.95);
          transform: translateY(50px) scale(0.95);
  margin: 0 0 2.5rem 0; }
  @media (min-width: 1200px) {
    .ItemProduct_divProductHosting__3OISL,
    .ItemProduct_divProductVPS__3lvyH,
    .ItemProduct_divProductDomain__CCcYX {
      margin: 2rem 2rem 2.5rem 2rem; } }

.ItemProduct_divProductHosting__3OISL {
  grid-area: hosting; }

.ItemProduct_divProductVPS__3lvyH {
  grid-area: vps; }

.ItemProduct_divProductDomain__CCcYX {
  grid-area: domain; }

.ItemProduct_img__XZklP {
  position: relative;
  width: 100%;
  cursor: pointer;
  box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.4); }
  .ItemProduct_imgProduct__33Nkt {
    display: block; }
  .ItemProduct_img__XZklP .ItemProduct_hiddenBox__3hmi8 {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    color: #fff;
    background-color: #029EF5;
    padding: 1.5rem;
    -webkit-transform: scale(0);
            transform: scale(0);
    -webkit-transform-origin: bottom left;
            transform-origin: bottom left; }
    @media (min-width: 1700px) {
      .ItemProduct_img__XZklP .ItemProduct_hiddenBox__3hmi8 {
        padding: 2.5rem; } }
  .ItemProduct_img__XZklP .ItemProduct_showBox__MOgy1 {
    -webkit-transform: scale(1);
            transform: scale(1); }
  .ItemProduct_img__XZklP .ItemProduct_text__1dYkZ {
    text-align: center;
    font-size: 1.1rem;
    font-weight: 300; }
    @media (min-width: 1700px) {
      .ItemProduct_img__XZklP .ItemProduct_text__1dYkZ {
        font-size: 1.4rem; } }
  .ItemProduct_imgProduct__33Nkt {
    width: 100%; }
  .ItemProduct_img__XZklP .ItemProduct_link__3fgx9 {
    position: relative;
    text-transform: uppercase;
    text-decoration: none;
    color: #fff;
    font-weight: 600;
    margin-top: 1rem;
    z-index: 5; }
    @media (min-width: 1700px) {
      .ItemProduct_img__XZklP .ItemProduct_link__3fgx9 {
        margin-top: 2rem;
        font-size: 1.4rem; } }
    .ItemProduct_img__XZklP .ItemProduct_link__3fgx9::before {
      position: absolute;
      content: '';
      bottom: 4px;
      left: 0;
      width: 100%;
      height: 2px;
      background: rgba(9, 203, 222, 0.8);
      z-index: -1;
      -webkit-transition: .4s;
      transition: .4s; }
    .ItemProduct_img__XZklP .ItemProduct_link__3fgx9:hover::before {
      height: 8px; }

.ItemProduct_underTitle__10GPu {
  font-size: 1.2rem;
  padding: .1rem .5rem;
  color: #029EF5;
  text-transform: uppercase; }
  @media (min-width: 1700px) {
    .ItemProduct_underTitle__10GPu {
      font-size: 1.4rem;
      font-weight: 900;
      letter-spacing: 1px;
      padding: .3rem .5rem; } }

.HeroSection_wrapper__1i7zY {
  width: 80%;
  margin: 0 auto;
  padding: 2rem 0; }
  @media (min-width: 1200px) {
    .HeroSection_wrapper__1i7zY {
      display: grid;
      grid-template-columns: 1fr 1fr;
      padding: 4rem 0;
      margin-bottom: 5rem; } }
  @media (min-width: 1700px) {
    .HeroSection_wrapper__1i7zY {
      width: 60%;
      padding: 6rem 0; } }

.HeroSection_titleSpan__23d3F {
  color: #029EF5;
  font-weight: 700; }

@media (min-width: 1700px) {
  .HeroSection_leftSide__3dqI9 {
    padding: 0 1rem; } }

.HeroSection_leftSide__3dqI9 .HeroSection_text__1Rk19 {
  grid-area: textAbout;
  font-size: 1.1rem;
  padding: 1rem 0;
  opacity: 0;
  -webkit-transform: translateY(60px);
          transform: translateY(60px); }
  @media (min-width: 1200px) {
    .HeroSection_leftSide__3dqI9 .HeroSection_text__1Rk19 {
      font-size: 1.4rem;
      padding: 2rem 0; } }

.HeroSection_leftSide__3dqI9 .HeroSection_link__11a80 {
  position: relative;
  text-transform: uppercase;
  text-decoration: none;
  color: #029EF5;
  font-weight: 600;
  margin: 1rem;
  z-index: 5; }
  @media (min-width: 1700px) {
    .HeroSection_leftSide__3dqI9 .HeroSection_link__11a80 {
      font-size: 1.4rem;
      font-weight: 700; } }
  .HeroSection_leftSide__3dqI9 .HeroSection_link__11a80::before {
    position: absolute;
    content: '';
    bottom: 0px;
    left: 0;
    width: 100%;
    height: 2px;
    background: rgba(9, 203, 222, 0.8);
    z-index: -1;
    -webkit-transition: .4s;
    transition: .4s; }
  .HeroSection_leftSide__3dqI9 .HeroSection_link__11a80:hover::before {
    height: 8px; }

.HeroSection_rightSide__1MG3d {
  padding: 2rem 0; }
  @media (min-width: 700px) {
    .HeroSection_rightSide__1MG3d {
      width: 50%;
      margin: 0 0 0 auto; } }
  @media (min-width: 1200px) {
    .HeroSection_rightSide__1MG3d {
      width: 90%;
      margin: 0 auto; } }
  .HeroSection_rightSide__1MG3d img {
    width: 100%;
    box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.4); }

.Footer_wrapper__2TmMH {
  width: 100%;
  background-color: #0A0A0A;
  color: #fff; }
  .Footer_wrapper__2TmMH .Footer_widthSize__13QMO {
    width: 100%; }
    @media (min-width: 1200px) {
      .Footer_wrapper__2TmMH .Footer_widthSize__13QMO {
        display: grid;
        grid-template-columns: 1fr 1fr;
        grid-template-rows: 1fr 1fr;
        grid-template-areas: "contact ." "address copyright";
        align-items: center;
        padding: 2rem 8rem; } }
    @media (min-width: 1700px) {
      .Footer_wrapper__2TmMH .Footer_widthSize__13QMO {
        width: 60%;
        padding: 2rem 0;
        margin: 0 auto; } }

.Footer_titleSpan__2M2X5 {
  color: #029EF5; }

.Footer_contact__2MzLo {
  padding: 2rem 1rem 1rem 1rem;
  grid-area: contact; }
  .Footer_contact__2MzLo .Footer_write__39jpn {
    font-weight: 900;
    letter-spacing: 1px; }
    @media (min-width: 1200px) {
      .Footer_contact__2MzLo .Footer_write__39jpn {
        font-size: 2.1rem; } }
  .Footer_contact__2MzLo .Footer_email__25gqG {
    position: relative;
    font-weight: 300;
    letter-spacing: 1px;
    color: #fff;
    text-decoration: none;
    z-index: 5; }
    @media (min-width: 1200px) {
      .Footer_contact__2MzLo .Footer_email__25gqG {
        font-size: 1.3rem; } }
    .Footer_contact__2MzLo .Footer_email__25gqG::before {
      position: absolute;
      content: '';
      bottom: -2px;
      left: 0;
      width: 100%;
      height: 2px;
      background: rgba(9, 203, 222, 0.8);
      z-index: -1;
      -webkit-transition: .4s;
      transition: .4s; }
    .Footer_contact__2MzLo .Footer_email__25gqG:hover::before {
      height: 10px; }
  .Footer_contact__2MzLo .Footer_callToActionWrapper__3BfUZ {
    width: 100%;
    padding: 2rem 0 1rem; }
    .Footer_contact__2MzLo .Footer_callToActionWrapper__3BfUZ .Footer_callToActionBtn__1V8NR {
      font-family: 'Montserrat', sans-serif;
      font-weight: 600;
      padding: .5rem 2.6rem;
      background: #029EF5;
      color: #ffffff;
      text-decoration: none;
      -webkit-transition: .4s;
      transition: .4s; }
      .Footer_contact__2MzLo .Footer_callToActionWrapper__3BfUZ .Footer_callToActionBtn__1V8NR:hover {
        background: #ffffff;
        color: #029EF5; }
      @media (min-width: 1200px) {
        .Footer_contact__2MzLo .Footer_callToActionWrapper__3BfUZ .Footer_callToActionBtn__1V8NR {
          padding: .7rem 4.3rem; } }

.Footer_address__1oVh_ {
  padding: 1rem 1rem 2rem;
  grid-area: address; }
  @media (min-width: 1200px) {
    .Footer_address__1oVh_ {
      font-size: 1.3rem; } }
  .Footer_address__1oVh_ .Footer_phone__321Lo {
    font-weight: 300;
    color: #fff;
    text-decoration: none; }

.Footer_copyright__FTzkR {
  grid-area: copyright;
  padding: 1.5rem;
  font-weight: 300;
  font-size: .8rem;
  text-align: center; }
  @media (min-width: 1200px) {
    .Footer_copyright__FTzkR {
      align-self: end; } }
  .Footer_copyright__FTzkR .Footer_blusoft__3Oj0t {
    color: #fff;
    text-decoration: none; }
  .Footer_copyright__FTzkR .Footer_frond__2-qDM {
    margin-top: .3rem; }

.Testimonial_wrapper__1776f {
  width: 80%;
  margin: 0 auto;
  padding: 2rem 0; }
  @media (min-width: 1200px) {
    .Testimonial_wrapper__1776f {
      padding: 4rem 0; } }
  @media (min-width: 1700px) {
    .Testimonial_wrapper__1776f {
      width: 60%; } }

.Testimonial_titleSpan__YUQgQ {
  color: #029EF5;
  font-weight: 700; }

.Testimonial_mySlider__2qnv1 {
  width: 100%;
  margin: 1rem 0 3rem; }

.Testimonial_divTitle__Dwg7V {
  margin: 0 auto; }
  @media (min-width: 1200px) {
    .Testimonial_divTitle__Dwg7V {
      display: grid;
      grid-template-columns: 45% 1fr;
      grid-template-areas: "titleAbout ."; } }
  @media (min-width: 1700px) {
    .Testimonial_divTitle__Dwg7V {
      width: 100%; } }

.Testimonial_myClass__2PK64 {
  box-shadow: 0px 0px 8px 0px rgba(0, 0, 0, 0.3);
  width: 100%;
  background-color: #029EF5;
  color: #fff;
  display: flex;
  margin-top: 3rem; }
  @media (min-width: 700px) {
    .Testimonial_myClass__2PK64 {
      margin-top: 2rem; } }
  @media (min-width: 1200px) {
    .Testimonial_myClass__2PK64 {
      width: 60%;
      margin: 1rem auto 0; } }
  @media (min-width: 1700px) {
    .Testimonial_myClass__2PK64 {
      -webkit-transform: translate(50px, -30px);
              transform: translate(50px, -30px); } }

.ItemTestimonial_oneTestimonial__3QoQo {
  display: flex;
  flex-direction: column;
  padding: 2rem 1rem;
  width: 100%;
  height: 100%; }
  @media (min-width: 1200px) {
    .ItemTestimonial_oneTestimonial__3QoQo {
      padding: 3rem; } }
  @media (min-width: 1700px) {
    .ItemTestimonial_oneTestimonial__3QoQo {
      padding: 3rem 3.5rem; } }
  .ItemTestimonial_oneTestimonialText__3Qlp1 {
    font-style: italic; }
    @media (min-width: 1200px) {
      .ItemTestimonial_oneTestimonialText__3Qlp1 {
        font-size: 1.1rem; } }
    @media (min-width: 1700px) {
      .ItemTestimonial_oneTestimonialText__3Qlp1 {
        font-size: 1.2rem; } }
  .ItemTestimonial_oneTestimonialCompany__3qynP {
    margin-top: 1rem;
    font-weight: 700;
    display: inline-flex;
    justify-content: flex-end; }
    @media (min-width: 1700px) {
      .ItemTestimonial_oneTestimonialCompany__3qynP {
        font-size: 1.4rem;
        margin-top: 2rem; } }

.WpAdmin_wrapper__2VvkV {
  width: 100%;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-content: center; }
  .WpAdmin_wrapper__2VvkV .WpAdmin_gifAnimation__33Q3i {
    display: block;
    width: 100%;
    box-shadow: 0px 0px 10px 1px rgba(0, 0, 0, 0.3); }
    @media (min-width: 1200px) {
      .WpAdmin_wrapper__2VvkV .WpAdmin_gifAnimation__33Q3i {
        width: 60%;
        margin: 0 auto; } }
    @media (min-width: 1600px) {
      .WpAdmin_wrapper__2VvkV .WpAdmin_gifAnimation__33Q3i {
        width: 50%;
        margin: 0 auto; } }

.ErrorPage_wrapper__2lJg- {
  width: 100%;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center; }
  .ErrorPage_wrapper__2lJg- .ErrorPage_title__2nGyo {
    font-weight: 900;
    font-size: 6em; }
  .ErrorPage_wrapper__2lJg- .ErrorPage_text__3QaIw {
    font-weight: 300;
    font-size: 1.3em;
    padding-bottom: 2rem; }
  .ErrorPage_wrapper__2lJg- .ErrorPage_backLink__3JdZ0 {
    position: relative;
    text-decoration: none;
    font-weight: 500;
    color: #029EF5;
    z-index: 5; }
    .ErrorPage_wrapper__2lJg- .ErrorPage_backLink__3JdZ0::after {
      position: absolute;
      content: '';
      left: 0;
      bottom: 0;
      width: 100%;
      height: 3px;
      background-color: rgba(10, 10, 10, 0.4);
      -webkit-transition: .4s;
      transition: .4s; }
    .ErrorPage_wrapper__2lJg- .ErrorPage_backLink__3JdZ0:hover::after {
      z-index: -1;
      height: 13px;
      background-color: rgba(163, 163, 163, 0.4); }

