.wrapper {
  width: 100%;
  margin: 0;
  background-color: #fff;
  padding: 1rem 0;

  @media (min-width:1200px) {
    margin: 3rem auto;
  }
  @media (min-width:1700px) {
    width: 60%;
  }
}

.divTitle {
  width: 80%;
  margin: 0 auto;
  
  @media (min-width:1200px) {
    display: grid;
    grid-template-columns: 50% 1fr;
    grid-template-areas: 
    "titleAbout .";
  }
  @media (min-width:1700px) {
    width: 100%;
  }
}

.titleSpan {
  color: #029EF5;
  font-weight: 700;
}