.wrapper {
  width: 80%;
  margin: 0 auto;
  padding: 2rem 0;

  @media (min-width:1200px) {
    display: grid;
    grid-template-columns: 1fr 1fr;
    padding: 4rem 0;
    margin-bottom: 5rem;
  }
  @media (min-width:1700px) {
    width: 60%;
    padding: 6rem 0;
  }
}

.titleSpan {
  color: #029EF5;
  font-weight: 700;
}

.leftSide {

  @media (min-width:1700px) {
    padding: 0 1rem;
  }

  .text {
    grid-area: textAbout;
    font-size: 1.1rem;
    padding: 1rem 0;
    opacity: 0;
    transform: translateY(60px);
    
    @media (min-width:1200px) {
      font-size: 1.4rem;
      padding: 2rem 0;
    }
  }

  .link {
    position: relative;
    text-transform: uppercase;
    text-decoration: none;
    color: #029EF5;
    font-weight: 600;
    margin: 1rem;
    z-index: 5;

    @media (min-width:1700px) {
      font-size: 1.4rem;
      font-weight: 700;
    }
  
    &::before {
      position: absolute;
      content: '';
      bottom: 0px;
      left: 0;
      width: 100%;
      height: 2px;
      background: rgba(9,203,222, .8);
      z-index: -1;
      transition: .4s;
    }
    &:hover::before {
      height: 8px;
    }
  }
}

.rightSide {
  padding: 2rem 0;

  @media (min-width:700px) {
    width: 50%;
    margin: 0 0 0 auto;
  }
  @media (min-width:1200px) {
    width: 90%;
    margin: 0 auto;
  }

  img {
    width: 100%;
    box-shadow: 0px 0px 5px 0px rgba(0,0,0,0.4);
  }
}