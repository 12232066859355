.wrapper {
  width: 100%;
  position: fixed;
  top: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: space-between;
  z-index: 10;
  padding: 1rem;
  // background-color: rgba(255, 255, 255, 0.6);

  @media (min-width:1700px) {
    padding: 1rem 5rem;
  }
}

.logo {
  width: 7em;
  padding: .5rem;

  @media (min-width:1700px) {
    width: 10em;
  }
}

.contact {
  text-decoration: none;
}

.text {
  position: relative;
  // padding: .5rem 1rem;
  font-weight: 900;
  color: #0A0A0A;
  cursor: pointer;

  &::before {
    position: absolute;
    content: '';
    bottom: 0px;
    left: 0;
    width: 100%;
    height: 2px;
    background: rgba(9,203,222, .8);
    z-index: -1;
    transition: .4s;
  }
  &:hover::before {
    height: 10px;
  }

  @media (min-width:1700px) {
    font-size: 1.3rem;
  }

  .titleSpan {
    color: #029EF5;
  }
}