.wrapper {
  width: 100%;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  .title {
    font-weight: 900;
    font-size: 6em;
  }

  .text {
    font-weight: 300;
    font-size: 1.3em;
    padding-bottom: 2rem;
  }

  .backLink {
    position: relative;
    text-decoration: none;
    font-weight: 500;
    color: #029EF5;
    z-index: 5;

    &::after {
      position: absolute;
      content: '';
      left: 0;
      bottom: 0;
      width: 100%;
      height: 3px;
      background-color: rgba(10,10,10, .4);
      transition: .4s;
    }

    &:hover::after {
      z-index: -1;
      height: 13px;
      background-color: rgba(163,163,163, .4);
    }
  }
}