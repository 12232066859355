.wrapper {
  width: 100%;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-content: center;

  .gifAnimation {
    display: block;
    width: 100%;
    box-shadow: 0px 0px 10px 1px rgba(0,0,0,0.3);

    @media (min-width:1200px) {
      width: 60%;
      margin: 0 auto;
    }

    @media (min-width:1600px) {
      width: 50%;
      margin: 0 auto;
    }
  }
}